import React from 'react';
import './JobHead.css'; // Import the CSS file for styling
import whatsappSvg from '../../assets/whatsapp.svg'
import telegramSvg from '../../assets/telegram.svg'
import newsSvg from '../../assets/news.svg'
import cardSvg from '../../assets/card.svg'
import { Link} from 'react-router-dom'


const JobHead = () => {
  return (
    <>
      <div className="job-head-container">
        <a href="https://whatsapp.com/channel/0029VaEh6uQDJ6H47RbvOr20" style={{textDecoration: 'none', color: 'black'}} >
        <div className="column">
          <img src={whatsappSvg} alt="WhatsApp Logo" />
          <p>Connect on Whatsapp</p>
        </div>
        </a>
        <a href="https://t.me/Rozgaar_Center" style={{textDecoration: 'none', color: 'black'}}>
        <div className="column">
          <img src={telegramSvg} alt="Telegram Logo" />
          <p>Connect on Telegram</p>
        </div>
        </a>
        <a href="https://t.me/Rozgaar_Center" style={{textDecoration: 'none', color: 'black'}}>
        <div className="column">
          <img src={newsSvg} alt="News Logo" />
          <p>Get Latest News</p>
        </div>
        </a>
        <a href="https://www.rozgaarcenter.com/admitCard" style={{textDecoration: 'none', color: 'black'}}>
        <div className="column">
          <img src={cardSvg} alt="Admit Card Logo" />
          <p>Admit Cards</p>
        </div>
        </a>

      </div>

      <div className='imp-links'>
        <ul>
          <li> <Link to='/admitCard'>Admit Card</Link> </li>
          <li> <Link to='/latestJob'>Latest Jobs</Link> </li>
          <li>  <Link to='/results'>Results</Link> </li>
          <li>  <Link to='/syllabus'>Syllabus</Link> </li>
          <li>  <Link to='/answerKey'>Answer Keys</Link> </li>
          <li>  <Link to='/others'>Others</Link> </li>
        </ul>
      </div>
      {/* 
    <div className='job-head-container me-hidee'>
      <div className="column">
        <img src={newsSvg} alt="News Logo" />
        <p>Get Latest News</p>
      </div>
      <div className="column">
        <img src={cardSvg} alt="Admit Card Logo" />
         <p>Admit Cards</p>
      </div>
    </div> */}
    </>
  );
};

export default JobHead;
