import React from 'react';
import './Footer.css'; // Make sure to import or include your CSS file
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">

          <div className="footer-col address">
            <h4>Rozgaar Center</h4>
            <ul>
            <li style={{marginBottom: "15px"}}><a href="/">Get Latest info about Latest job, Results, Admit Cards, Syllabus only at Rozgaar Center</a></li>
              <li><a href="/"><i className="fa-solid fa-house"></i> India</a></li>
              <li><a href="/"><i className="fa-solid fa-phone"></i>+91 xxxxxxxx</a></li>
              <li><a className='addresss' href="mailto:rozgaarcenter2024@gmail.com"><i className="fa-solid fa-envelope-open"></i>rozgaarcenter2024@gmail.com</a></li>
            </ul>
          </div>

          <div className="footer-col">
            <h4>company</h4>
            <ul>
              <li><Link to="/about-us">About us</Link></li>
              <li><Link to="/our-services">Our Services</Link></li>
              {/* <li><a href="#">Shipping & Delivery</a></li> */}
              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
              {/* <li><a href="#">affiliate program</a></li> */}
            </ul>
          </div>
          <div className="footer-col">
            <h4>online shop</h4>
            <ul>
              <li><Link to="/shop">Patang</Link></li>
              <li><Link to="/shop">Manja</Link></li>
              <li><Link to="/shop">Lantern</Link></li>
              <li><Link to="/shop">Kids Section</Link></li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>follow us</h4>
            <div className="social-links">
              <Link to="https://whatsapp.com/channel/0029VaEh6uQDJ6H47RbvOr20"><i className="fa-brands fa-youtube"></i></Link>
              <Link to="https://twitter.com/Rozgaar_Center"><i className="fab fa-twitter"></i></Link>
              <Link to="https://www.youtube.com/channel/UC0ZiieC0RV_5pXDYd2D8gQQ"><i className="fa-brands fa-youtube"></i></Link>
              <Link to="https://t.me/Rozgaar_Center"><i className="fa-brands fa-telegram"></i></Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
