import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../..'
import './JobAdd.css'
import axios from 'axios';

const JobAdd = ({jobType}) => {
  const { dispatch } = useContext(AppContext)
  const [formData, setFormData] = useState({
    jobName: '',
    jobShortName: '',
    image: null,
    description: '',
    slug: '',
    dates: [{}],
    fees: [{}],
    ageLimits: [{}],
    importantLinks: [],
    tags: '',
  });

  const [  , setSubmitting] = useState(false);


  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {

      const formDataToSend = new FormData();
      formDataToSend.append('jobName', formData.jobName);
      formDataToSend.append('jobShortName', formData.jobShortName);
      formDataToSend.append('image', formData.image);
      formDataToSend.append('description', formData.description);
      formDataToSend.append('slug', formData.slug);

      formDataToSend.append('tags', formData.tags);

    // Append array fields
    formData.dates.forEach((date, index) => {
      formDataToSend.append(`dates[${index}][title]`, date.title);
      formDataToSend.append(`dates[${index}][date]`, date.date);
    });

    formData.fees.forEach((fee, index) => {
      formDataToSend.append(`fees[${index}][category]`, fee.category);
      formDataToSend.append(`fees[${index}][amount]`, fee.amount);
    });

    formData.ageLimits.forEach((limit, index) => {
      formDataToSend.append(`ageLimits[${index}][extraInfo]`, limit.extraInfo);
    });

    formData.importantLinks.forEach((link, index) => {
      formDataToSend.append(`importantLinks[${index}][linkName]`, link.linkName);
      formDataToSend.append(`importantLinks[${index}][link]`, link.link);
    });

      axios.post(`${process.env.REACT_APP_URI}/job/${jobType}/add`, formDataToSend)
      setSubmitting(false);
      alert('Submitted successfully!');
    } catch (err) {
      setSubmitting(false);
      console.log(err);
    }
  };
  
  useEffect(() => {
    dispatch({ type: 'UPDATE_LOADER', payload: false });
  }, [dispatch]);


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log("Selected file:", file); // Log the selected file
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: file,
    }));
  };

  const handleArrayChange = (array, index, field, value) => {
    const updatedArray = [...formData[array]];
    updatedArray[index][field] = value;
    setFormData({
      ...formData,
      [array]: updatedArray,
    });
  };



  
  const addFee = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      fees: [...prevFormData.fees, {}],
    }));
  };

  const removeFee = (index) => {
    let fees = [...formData.fees];
    fees.splice(index, 1);

    setFormData({
      ...formData,
      fees,
    });
  };

  const addDate = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      dates: [...prevFormData.dates, {}],
    }));
  };

  const removeDate = (index) => {
    let dates = [...formData.dates];
    dates.splice(index, 1);
  
    setFormData({
      ...formData,
      dates,
    });
  };

  

  const addAgeLimit = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ageLimits: [...prevFormData.ageLimits, {}],
    }));
  };

  const removeAgeLimit = (index) => {
    let ageLimits = [...formData.ageLimits];
    ageLimits.splice(index, 1);
  
    setFormData({
      ...formData,
      ageLimits,
    });
  };


  const addLink = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      importantLinks: [...prevFormData.importantLinks, {}],
    }));
  };

  const removeLink = (index) => {
    let links = [...formData.importantLinks];
    links.splice(index, 1);

    setFormData({
      ...formData,
      importantLinks: links,
    });
  };

  return (
    <div className="job-result-container">
  <h1>Add Job Result</h1>
  <form className="job-result-form" onSubmit={handleSubmit}>

    <label className="form-label" htmlFor="jobName">Job Name:</label>
    <input
      className="form-input"
      name="jobName"
      value={formData.jobName}
      onChange={handleChange}
    />

    <label className="form-label" htmlFor="jobShortName">Job Short Name:</label>
    <input
      className="form-input"
      name="jobShortName"
      value={formData.jobShortName}
      onChange={handleChange}
    />


<label className="form-label" htmlFor="image">
        Image:
      </label>
      <input
        type="file"
        name="image"
        id="image"
        accept="image/*"
        onChange={handleImageChange}
      />

    <label className="form-label" htmlFor="description">Description:</label>
    <textarea
      className="form-input"
      name="description"
      value={formData.description}
      onChange={handleChange}
    />

    <label className="form-label" htmlFor="slug">Slug:</label>
    <input
      className="form-input"
      name="slug"
      value={formData.slug}
      onChange={handleChange}
    />

<button className="form-button" type="button" onClick={addDate}>
      Add Date
    </button>

{formData.dates.map((date, index) => (
  <div className="important-dates-item" key={`date-${index}`}>
    <label className="form-label" htmlFor={`title-${index}`}>Important Date Title:</label>
    <input
      className="form-input"
      name={`title-${index}`}
      value={date.title}
      onChange={e => handleArrayChange('dates', index, 'title', e.target.value)}
    />

    <label className="form-label" htmlFor={`date-${index}`}>Date:</label>
    <input
      className="form-input"
      name={`date-${index}`}
      value={date.date}
      onChange={e => handleArrayChange('dates', index, 'date', e.target.value)}
    />

    <button className="form-button" type="button" onClick={() => removeDate(index)}>
      Remove
    </button>

  </div>
))}

    {/* <div className="fees-section">
      <h2>Current Fees:</h2>
      {formData.fees.map((fee, index) => (
        <div className="fee-item" key={index}>
          <p>Category: {fee.category}</p>
          <p>Amount: {fee.amount}</p>
        </div>
      ))}
    </div> */}

    <button className="form-button" type="button" onClick={addFee}>
      Add Fee
    </button>

    {formData.fees.map((fee, index) => (
      <div className="fee-item" key={`fee-${index}`}>
        <label className="form-label" htmlFor={`category-${index}`}>Category:</label>
        <input
          className="form-input"
          name={`category-${index}`}
          value={fee.category}
          onChange={(e) => handleArrayChange('fees', index, 'category', e.target.value)}
        />

        <label className="form-label" htmlFor={`amount-${index}`}>Amount:</label>
        <input
          className="form-input"
          name={`amount-${index}`}
          value={fee.amount}
          onChange={(e) => handleArrayChange('fees', index, 'amount', e.target.value)}
        />

        <button className="form-button" type="button" onClick={() => removeFee(index)}>
          Remove
        </button>
      </div>
    ))}


<button className="form-button" type="button" onClick={addAgeLimit}>
      Add Additional Info
    </button>
{formData.ageLimits.map((limit, index) => (
  <div className="age-limits-item" key={`ageLimit-${index}`}>
    <label className="form-label" htmlFor={`extraInfo-${index}`}>Extra Info:</label>
    <input
      className="form-input"
      name={`extraInfo-${index}`}
      value={limit.extraInfo}
      onChange={e => handleArrayChange('ageLimits', index, 'extraInfo', e.target.value)}
    />

    <button className="form-button" type="button" onClick={() => removeAgeLimit(index)}>
      Remove
    </button>
  </div>
))}


<button className="form-button" type="button" onClick={addLink}>
          Add Important Link
        </button>

        {formData.importantLinks.map((link, index) => (
          <div className="important-links-item" key={`link-${index}`}>
            <label className="form-label" htmlFor={`linkName-${index}`}>Link Name:</label>
            <input
              className="form-input"
              name={`linkName-${index}`}
              value={link.linkName}
              onChange={(e) => handleArrayChange('importantLinks', index, 'linkName', e.target.value)}
            />

            <label className="form-label" htmlFor={`link-${index}`}>Link:</label>
            <input
              className="form-input"
              name={`link-${index}`}
              value={link.link}
              onChange={(e) => handleArrayChange('importantLinks', index, 'link', e.target.value)}
            />

            <button className="form-button" type="button" onClick={() => removeLink(index)}>
              Remove
            </button>
            </div>
        ))}

<label className="form-label" htmlFor="tags">TAGS:</label>
    <textarea
      className="form-input"
      name="tags"
      value={formData.tags}
      onChange={handleChange}
    />

    <button className="form-button" type="submit">Submit</button>

  </form>
</div>


  );
};

export default JobAdd;
