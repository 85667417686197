import { useContext } from "react";
import { Routes, Route } from 'react-router-dom'
import "./App.css";
import { Landing } from "./Pages/Landing/Landing";
import { Login } from "./Pages/Auth/Login";
import { Products } from './Pages/Products/Products.jsx';
// import { ProductDetails } from "./Pages/Products/ProductDetails";
import { Cart } from "./Pages/Cart/Cart";
import Signup from "./Pages/Auth/Signup";
import { Wishlist } from "./Pages/Wishlist/Wishlist";
import RequireAuth from "./Pages/Auth/RequireAuth";
import { AppContext } from ".";
import Loader from "./Pages/Loaders/Loader";
import Checkout from "./Pages/Checkout/Checkout";
import Error from "./Pages/Error/Error";
import { Navbar } from "./components/Navbar/Navbar";
// import FestivalModal from "./components/FestivalModal/FestivalModal.jsx";
import JobPage from "./Pages/JobPage/JobPage.jsx";
// import JobResultAdd from "./Pages/AddJob/JobAdd.jsx";
// import JobAdmitCardAdd from "./Pages/AddJob/JobAdmitCardAdd.jsx";
import JobAdd from "./Pages/AddJob/JobAdd.jsx";
import AllJob from "./Pages/AllJob/AllJob.jsx";
import Layout from "./Layout/Layout.js";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy.jsx";
import AboutUs from "./components/PrivacyPolicy/AboutUs.jsx";
import OurServices from "./components/PrivacyPolicy/OurServices.jsx";



function App() {


  const { state } = useContext(AppContext)
  // const location = useLocation()

  return (
    <div className="App">
      {
        state.isLoader ? <Loader /> : ''
      }
      {/* {
        state.showFestivalModal && location.pathname !== '/error' ?
          <>
            <FestivalModal />
            <div className="kite">
              <div className="tail"></div>
            </div>

            <div className="kite kite-left">
              <div className="tail"></div>
            </div>
          </> : ''

      } */}



      <Navbar />
      <Routes>
        <Route path='/' element={
          <Layout><Landing /></Layout>} />
        <Route path='/results/:slug' element={<JobPage jobType="jobResult" jobName="Results" jobURL="results" />} />
        <Route path='/addJob/result' element={<JobAdd jobType="jobResult" />} />

        <Route path='/results' element={<Layout title="Latest Results 2024 | Recruitment Results | All Exam Result - Rozgaar Center" description="RozgaarCenter: Get Updated Results 2024 Get update for all Rojgar Results the various Exam Results like Entrance Results, Railway Results, Mains Results, Shikshamitra Results and more. ✔ Free Job Alert ✔ Frequently Updated" keywords="Results, Latest Results, Rozgaar Center, Latest Rozgaar Centers, All Exam Results, 2024 Exam results" >
          <AllJob jobType="jobResult" jobName="Results" />       </Layout>} />


        <Route path='/admitCard/:slug' element={<JobPage jobType="jobAdmitCard" jobName="Admit Card" jobURL="admitCard" />} />
        <Route path='/addJob/admitCard' element={<JobAdd jobType="jobAdmitCard" />} />

        <Route path='/admitCard' element={<Layout title="Download Latest Exam Admit Card for All Exam 2024" description="Download All Latest Exams Admit Card, Hall Ticket or Call Letter for various government job Banks, TET, SSC, UPSC, Railway at Rozgaar Center. ✔ Frequently Update" keywords="Rozgaar Center Admit Card, Admit Card, All Exam Admit Card, Admit Card 2024">
          <AllJob jobType="jobAdmitCard" jobName="Admit Card" /></Layout>} />

        <Route path='/latestJob/:slug' element={<JobPage jobType="jobLatestJob" jobName="Latest Job" jobURL="latestJob" />} />
        <Route path='/addJob/latestJob' element={<JobAdd jobType="jobLatestJob" />} />

        <Route path='/latestJob' element={<Layout title="Check Latest Job 2024 for All Exam - Rozgaar Center" description="Get Updates About Latest Exams 2024, Vacancies and Other Government opportunities" keywords="Recruitment, Jobs Advertisement, Latest Recruitment, Notifications, Rozgaar Center, Rozgaar Center, Sarkari Exam, Rozgaar Result, Government Vacancies">
          <AllJob jobType="jobLatestJob" jobName="Latest Job" /></Layout>} />
          
        <Route path='/syllabus/:slug' element={<JobPage jobType="jobSyllabus" jobName="Syllabus" jobURL="syllabus" />} />
        <Route path='/addJob/syllabus' element={<JobAdd jobType="jobSyllabus" />} />
        <Route path='/syllabus' element={<Layout title="Download Syllabus for All Competition Exams 2024" description="Download Latest Syllabus for all competition exams such as SSC, UPSC, Railway, Bank, Police, JSSC & more through RozgaarCenter" keywords="Syllabus, Sarkari, Sarkari Syllabus, Rozgaar Center, Rojgar, RojgarResult Download Syllabus">
          <AllJob jobType="jobSyllabus" jobName="Syllabus" /></Layout>} />

        <Route path='/answerKey/:slug' element={<JobPage jobType="jobAnswerKey" jobName="Answer Key" jobURL="answerKey" />} />
        <Route path='/addJob/answerKey' element={<JobAdd jobType="jobAnswerKey" />} />
        <Route path='/answerKey' element={<Layout title="Latest Answer Key for All Exams | Question Paper 2024" description="Download Latest Recruitment Exam Answer Key, Question Paper, Online Objection 2024, Rozgaar Center, Frequently Update" keywords="Rozgaar Center Answer Key, UPPSC Answer Key, SSC Answer Key, UPSSSC Answer Key 2024, All Admission Answer Key, Question Paper Download, Rozgaar Answer Key" >
          <AllJob jobType="jobAnswerKey" jobName="Answer Key" /></Layout>} />

        <Route path='/others/:slug' element={<JobPage jobType="jobOthers" jobName="Others" jobURL="others" />} />
        <Route path='/addJob/others' element={<JobAdd jobType="jobOthers" />} />
        <Route path='/others' element={<Layout title="UP Scholarship | Driving License 2024 | Important Updates" description="Check Very Important Link at Rozgaar Center Driving License, Scholarship, Rojgar Panjiyan, MP, UP, Rajasthan, Bihar, and More" keywords="Rojgar Panjiyan, UP Scholarship, UP Learning License, Driving License, Rozgaar Center">
          <AllJob jobType="jobOthers" jobName="Others" /></Layout>} />


        <Route path='/privacy-policy' element={<Layout title="Privacy Policy - Rozgaar Center" description="Privacy Policy Rozgaar Center Check Privacy and Policy Details." keywords="Privacy Policy Rozgaar Center"><PrivacyPolicy /></Layout>} />
        <Route path='/about-us' element={<AboutUs />} title="" description="" keywords="" />
        <Route path='/our-services' element={<OurServices />} title="" description="" keywords="" />
        
        <Route path='/login' element={<Layout title="Login to RozgaarCenter.com" description="Login to RozgaarCenter.com and enjoy shopping"><Login /></Layout>} />
        <Route path='/shop' element={<Layout title="Explore Copies, Paper Rims, Kites, Manjas, Lalterns & Deals" description="Explore a variety of products including copies, paper rims, kites, manjas, and lalterns. Find the best deals and latest offerings."
      keywords="Copies, Paper Rim, Kites, Manjas, Laltern, products, deals, listings"><Products /></Layout>} />
        {/* <Route path='/shop/:bookID' element={<ProductDetails />} /> */}
        <Route path='/cart' element={
          <RequireAuth>
            <Layout title="My Cart"><Cart /></Layout>
          </RequireAuth>
        } />
        <Route path='/signup' element={<Layout title="Signup to RozgaarCenter.com" description="Create your account at RozgaarCenter.com, and enjoy shopping and best deals"><Signup /></Layout>} />
        <Route path='/wishlist' element={
          <RequireAuth>
            <Layout title="My Wishlist"><Wishlist /></Layout>
          </RequireAuth>
        } />
        <Route path='/checkout' element={
          <RequireAuth>
            <Layout title="Checkout"><Checkout /></Layout>
          </RequireAuth>
        } />
        <Route path='/error' element={
          <Layout title="Error" description="Error Page"><Error /></Layout>
        } />

      </Routes>

    </div>
  );
}

export default App;
