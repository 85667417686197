import React, { useState, useEffect, useContext } from 'react'; 
import axios from 'axios';
import { AppContext } from '../..'
import './AllJob.css'
import { Link } from "react-router-dom";
import Footer from '../../components/Footer/Footer';

const AllJob = ({ jobType, jobName }) => {

  const [jobData, setJobData] = useState([]);
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URI}/job/${jobType}/all`)
      .then(res => {
        setJobData(prevState => ({ ...prevState, results: res.data }));
      })
      .catch(error => console.error('Error fetching result jobs:', error));
  }, [jobType]);

  useEffect(() => {
    dispatch({ type: 'UPDATE_LOADER', payload: false });
  }, [dispatch]);

  return (
    <>
    <div className='job-page'>
  <div className="job-section">
    <h2>{jobName}</h2>
    <ul>
      {jobData.results && jobData.results.map(job => (
        <li key={job.slug}>
          <Link to={`/results/${job.slug}`}>
            {job.jobName}
          </Link>
        </li>
      ))}
    </ul>
  </div>

  <div className="connect-section">
    <h2>Connect with us</h2>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
      Connect with us for the latest updates and job opportunities.
    </p>
    <a href="mailto:info@example.com">Email us</a>
    <p>Follow us on social media:</p>
    <div>
      <a href="t.me/rozgaar_center" target="_blank" rel="noopener noreferrer">Twitter</a>
      <a href="t.me/rozgaar_center" target="_blank" rel="noopener noreferrer">LinkedIn</a>
      <a href="t.me/rozgaar_center" target="_blank" rel="noopener noreferrer">Facebook</a>
    </div>
</div>
</div>
<Footer />
</>
  );
}

export default AllJob;
