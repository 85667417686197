import React from 'react';
import './AddressProfile.css'; // Import your CSS file

const AddressProfile = ({
  showAddressAdditionPage,
  state,
  dispatch,
  setShowAddressAdditionPage,
  toast,
}) => {
  return (
    <div className={`address-profile-page ${showAddressAdditionPage ? 'show' : ''}`}>
      <div className="address-profile-container">
        <h2 className="login-profile-container-heading">ADD NEW ADDRESS</h2>
        <label htmlFor="name">
          <input
            type="text"
            value={state.newAddress.name}
            placeholder="Enter Name"
            required
            onChange={(event) =>
              dispatch({
                type: 'UPDATE_NAME',
                payload: event.target.value,
              })
            }
          />
        </label>
        <label htmlFor="colony">
          <input
            type="text"
            value={state.newAddress.colony}
            required
            placeholder="Enter House No., Road, Colony Name"
            onChange={(event) =>
              dispatch({
                type: 'UPDATE_COLONY',
                payload: event.target.value,
              })
            }
          />
        </label>
        <label htmlFor="city">
          <input
            type="text"
            required
            value={state.newAddress.city}
            placeholder="Enter City"
            onChange={(event) =>
              dispatch({
                type: 'UPDATE_CITY',
                payload: event.target.value,
              })
            }
          />
        </label>
        <label htmlFor="state">
          <input
            type="text"
            required
            value={state.newAddress.state}
            placeholder="Enter State"
            onChange={(event) =>
              dispatch({
                type: 'UPDATE_STATE',
                payload: event.target.value,
              })
            }
          />
        </label>
        <label htmlFor="country">
          <input
            type="text"
            required
            value={state.newAddress.country}
            placeholder="Enter Country"
            onChange={(event) =>
              dispatch({
                type: 'UPDATE_COUNTRY',
                payload: event.target.value,
              })
            }
          />
        </label>
        <label htmlFor="postal-code">
          <input
            type="number"
            required
            value={state.newAddress.postalCode}
            placeholder="Enter Postal Code"
            onChange={(event) =>
              dispatch({
                type: 'UPDATE_POSTAL_CODE',
                payload: event.target.value,
              })
            }
          />
        </label>
        <label htmlFor="mobile-number">
          <input
            type="number"
            required
            value={state.newAddress.mobileNumber}
            placeholder="Enter Mobile Number"
            onChange={(event) =>
              dispatch({
                type: 'UPDATE_MOB_NO',
                payload: event.target.value,
              })
            }
          />
        </label>
        <section className="btn-section-address">
          <button
            type="submit"
            onClick={() => {
              if (
                state.newAddress.name &&
                state.newAddress.colony &&
                state.newAddress.city &&
                state.newAddress.state &&
                state.newAddress.country &&
                state.newAddress.postalCode &&
                state.newAddress.mobileNumber
              ) {
                dispatch({
                  type: 'ADD_NEW_ADDRESS',
                  payload: state.newAddress,
                });
                dispatch({
                  type: 'UPDATE_NEW_ADDRESS',
                  payload: {
                    name: '',
                    colony: '',
                    city: '',
                    state: '',
                    country: '',
                    postalCode: '',
                    mobileNumber: '',
                  },
                });
                toast.success('Address Added Successfully!', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'light',
                });
                setShowAddressAdditionPage(false);
              }
            }}
            className="btn-save"
          >
            Save
          </button>
          <button
            onClick={() => {
              dispatch({
                type: 'UPDATE_NEW_ADDRESS',
                payload: {
                  name: '',
                  colony: '',
                  city: '',
                  state: '',
                  country: '',
                  postalCode: '',
                  mobileNumber: '',
                },
              });
              setShowAddressAdditionPage(false);
            }}
            className="btn-cancel"
          >
            Cancel
          </button>

        </section>
      </div>
    </div>
  );
};

export default AddressProfile;
