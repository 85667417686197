import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../..'
import axios from 'axios';
import './JobPage.css'
import Footer from '../../components/Footer/Footer';
import Layout from '../../Layout/Layout';

const JobPage = ({jobType, jobName, jobURL}) => {
  const { slug } = useParams();
  console.log(slug)
  const [jobDetails, setJobDetails] = useState(null);
  const { dispatch } = useContext(AppContext)
  const [resultJobs, setResultJobs] = useState([]);


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URI}/job/${jobType}/details/${slug}`)
      .then(response => {
        setJobDetails(response.data);
      })
      .catch(error => console.error('Error fetching job details:', error));
    dispatch({ type: 'UPDATE_LOADER', payload: false })

  }, [slug, jobType, dispatch]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URI}/job/${jobType}`)
      .then(res => {
        setResultJobs(res.data);
      })
      .catch(error => console.error('Error fetching result jobs:', error));
  }, [jobType]);

  if (!jobDetails) {
    return <div>Loading...</div>;
  }

  // const tableStyle = {
  //   borderCollapse: 'collapse',
  //   width: '80%', 
  // };

  const cellStyle = {
    border: '1px solid #ccc', // Border style
    padding: '10px',
    textAlign: 'left', // Adjust text alignment as needed
  };

  const labelStyle = {
    fontWeight: 'bold',
  };

  const linkStyle = {
    textDecoration: 'none',
    color: 'blue', // Adjust link color as needed
  };

  return (
    <>

<Layout
  title={jobDetails.jobShortName}
  description="Get information about the latest Jobs, admit card, and results in detailed view. Also the links for further information"
  keywords={jobDetails.tags}
>
      <div className="job-body" style={{ display: 'flex' }}>

<div className='left-column'>
  <h2>Name of Post: {jobDetails.jobName}</h2>
  <p> <b>Description of Post:</b> {jobDetails.description}</p>

  {jobDetails.image && (
    
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15px' }}>
    <img 
      src={`${process.env.REACT_APP_URI}${jobDetails.image}`} 
      alt=""
      style={{ maxWidth: '100%', objectFit: '' }} // Maintain aspect ratio
    />
  </div>
)}

  <table className='tableStyle'>


    <tbody className='table-body'>
      
        <tr>
          <td colSpan="2" style={cellStyle}>
            <h2>Important Dates</h2>
          </td>
        </tr>

        {jobDetails.dates.map(date => (
          <tr key={date.title} >
            {/* <td style={cellStyle}>
</td> */}

            <td colSpan="2">
              <li> <b>{date.title} </b>: {date.date}</li>
            </td>
          </tr>
        ))}
      <tr>

        <td colSpan="2" style={cellStyle}>
          <h2>Application Fees</h2>
        </td>
      </tr>

      {jobDetails.fees.map(fee => (
        <tr key={fee.category}>
          {/* <td style={cellStyle}>
</td> */}

          <td colSpan="2">
            <li> <b>{fee.category}:</b> {fee.amount}</li>
          </td>
        </tr>
      ))}


      <tr>
        <td colSpan="2" style={cellStyle}>
          <h2>Additional Info</h2>
        </td>
      </tr>

      {jobDetails.ageLimits.map(ageLimit => (
        <tr key={ageLimit.extraInfo}>
          {/* <td style={cellStyle}>
</td> */}

          <td colSpan="2">
            <li style={{fontWeight: "500"}}>{ageLimit.extraInfo}</li>
          </td>
        </tr>
      ))}

    </tbody>
    <tbody>

    <tr>
        <td colSpan="2" style={cellStyle}>
          <h2>Some Useful Important Links</h2>
        </td>
      </tr>

      {jobDetails.importantLinks.map((link, index) => (
<tr key={`link-${index}`}>
<td style={{ ...cellStyle, ...labelStyle }}>{link.linkName}</td>
<td style={cellStyle}>
<a href={link.link} target="_blank" rel="noopener noreferrer" style={linkStyle}>
Click Here
</a>
</td>
</tr>
))}

      <tr>
        <td style={{ ...cellStyle, ...labelStyle }}>Join Our Telegram Channel</td>
        <td style={cellStyle}>
          <a href="https://example.com/apply" target="_blank" rel="noopener noreferrer" style={linkStyle}>
            Click Here
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div className='right-column job-two-hide'>
  <h2>New {jobName}</h2>

  <ul>
    {resultJobs.map(job => (
      <li key={job.slug}>
        <a href={`/${jobURL}/${job.slug}`}>
          {job.jobName}
        </a>
      </li>
    ))}
  </ul>
</div>

</div>
      </Layout>

      <Footer />

    </>



  );
};

export default JobPage;
