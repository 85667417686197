import React, {useEffect, useContext} from 'react';
import { AppContext } from '../..'
import './OurServices.css'; // Import your CSS file for styling
import Footer from '../Footer/Footer';

const OurServices = () => {

    const { dispatch } = useContext(AppContext)

    useEffect(() => {
        dispatch({ type: 'UPDATE_LOADER', payload: false });
    }, [dispatch]);
  return (
    <>
    <div className="our-services-container">
      <h1 className="our-services-title">Our Services</h1>
      <p className="our-services-content">
        Explore the services offered by <a href="https://www.Rozgaarcenter.com">Rozgaarcenter.com</a> to enhance your job search and recruitment experience.
        <br /><br />
        <strong>1. Job Listings:</strong><br />
        Browse through a diverse range of job listings from various industries. Our platform provides comprehensive information about each job opportunity to help you make informed decisions.
        <br /><br />
        <strong>2. Ecommerce Store</strong><br />
        Choose from various different products and list them to your wishlist or order them with our seamless platform.
        <br /><br />
        <strong>3. User-Friendly Platform:</strong><br />
        Our website is designed with user experience in mind. Easily navigate through job listings, create your profile, and manage your applications effortlessly.
        <br /><br />
        <strong>4. Job Alerts:</strong><br />
        Stay updated on the latest job opportunities with our job alert feature. Receive notifications based on your preferences, ensuring you never miss out on relevant opportunities.
        <br /><br />
        <strong>5. Support and Resources:</strong><br />
        We are dedicated to providing support to both job seekers and employers. Access resources, FAQs, and reach out to our support team for assistance.
        <br /><br />
        <strong>Contact Us:</strong><br />
        For inquiries or more information about our services, please contact us at <a href="mailto:rozgaarcenter2024@gmail.com">rozgaarcenter2024@gmail.com</a>.
      </p>
    </div>

    <Footer />
    </>
  );
};

export default OurServices;