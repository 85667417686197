import { useContext, useEffect, Suspense  } from 'react'
import { AppContext } from '../..'
import './Landing.css'
import { Link, useNavigate } from 'react-router-dom'
// import ExclusiveLanding from '../../components/ManjaLanding/ExclusiveLanding'
// import Footer from '../../components/Footer/Footer'
import JobList from '../../components/JobList/JobList'
import JobHead from '../../components/JobHead/JobHead'
import Loadable from '@loadable/component';
import Loader from '../Loaders/Loader'


export function Landing() {
    const { dispatch } = useContext(AppContext)
    
    const LazyJobList = Loadable(() => import('../../components/Footer/Footer'));

    const navigate = useNavigate()

    const token = sessionStorage.getItem('token');

    const getLandingData = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_URI}/categories`
            )
            const jsonResponse = await response.json()
            dispatch({
                type: 'UPDATE_CATEGORIES',
                payload: jsonResponse.categories,
            })
            dispatch({ type: 'UPDATE_LOADER', payload: false })
        } catch (error) {
            navigate('/error')
        }
    }

    const getCartData = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_URI}/user/cart`,
                {
                    method: 'GET',
                    headers: {
                        authorization: sessionStorage.getItem('encodedToken'),
                        'Content-type': 'application/json',
                    },
                }
            )
            const jsonResponse = await response.json()
            dispatch({ type: 'UPDATE_CART', payload: jsonResponse.cart })
            dispatch({ type: 'UPDATE_LOADER', payload: false })
        } catch (error) {
            navigate('/error')
        }
    }

    const getWishList = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_URI}/user/wishlist`,
                {
                    method: 'GET',
                    headers: {
                        authorization: sessionStorage.getItem('encodedToken'),
                        'Content-type': 'application/json',
                    },
                }
            )
            const jsonResponse = await response.json()

            dispatch({
                type: 'UPDATE_WISHLIST',
                payload: jsonResponse.wishlist,
            })
            dispatch({ type: 'UPDATE_LOADER', payload: false })
        } catch (error) {
            navigate('/error')
        }
    }
    

    useEffect(() => {
        
    if(token)
    {
        getLandingData()
        getWishList()
        getCartData()
    }
    dispatch({ type: 'UPDATE_LOADER', payload: false })
        // eslint-disable-next-line
    }, [])

    return (
        <div className="landing-page">
            <section className="shop-now-section">
                <div className="show-now-section-info">

                    <Link
                        to="/shop"
                        className="shop-now-btn"
                        onClick={() =>
                            dispatch({ type: 'UPDATE_LOADER', payload: true })
                        }
                    >
                        Shop Now {'>'}
                    </Link>
                </div>
            </section>
            <JobHead />

            <JobList />
            
            <Suspense fallback={<Loader />}>
    <LazyJobList /> 
  </Suspense>

            

            {/* <Footer /> */}

           
        </div>
    )
}
