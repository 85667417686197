import React, { useState, useContext } from 'react'
import './Checkout.css'
import { AppContext } from '../..'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router'
import AddressProfile from './AddressProfile'

function Checkout() {

    const [showAddressAdditionPage, setShowAddressAdditionPage] = useState(false)
    const { state, dispatch } = useContext(AppContext)
    const navigate = useNavigate()

    const calculatePrice = (list) => {
        return list.length
            ? list.reduce(
                (acc, curr) => acc + curr.book.originalPrice * curr.quantity,
                0
            )
            : '0'
    }

    const calculateDiscount = (list) => {
        return list.length
            ? list.reduce(
                (acc, curr) =>
                    acc +
                    (curr.book.originalPrice - curr.book.price) *
                    curr.quantity,
                0
            )
            : '0'
    }

    const calculateTotalAmount = (list) => {
        const amount = calculatePrice(list) - calculateDiscount(list)
        return amount
    }

    const calculateCouponDiscount = (list) => {
        return state.couponData.couponDiscount > 0
            ? (
                state.couponData.couponDiscount *
                0.01 *
                calculateTotalAmount(list)
            ).toFixed(2)
            : 0
    }

    const emptyCart = async (list) => {
        for (let index = 0; index < list?.length; index++) {
            const bookId = list[index].book._id
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_URI}/user/cart/${bookId}`,
                    {
                        method: 'DELETE',
                        headers: {
                            authorization: sessionStorage.getItem('encodedToken'),
                            'Content-type': 'application/json',
                        },
                    }
                )
                const jsonResponse = await response.json()
                dispatch({ type: 'UPDATE_CART', payload: jsonResponse.cart })
                dispatch({ type: 'UPDATE_LOADER', payload: false })
            } catch (err) {
                navigate('/error')
            }
        }
    }

    const totalAmount = (list) => {
        return calculateTotalAmount(list) - calculateCouponDiscount(list)
    }

    const placeOrderBtnHandler = async () => {
        if (state.deliveryAddress === null) {
            toast.error('Please select an address for checking out!');
        } else if (state.cartList?.length === 0) {
            toast.error('Please add something to cart for checking out!');
            navigate('/cart');
        } else {
            try {
                const response = await fetch(`${process.env.REACT_APP_URI}/order`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        deliveryAddress: state.deliveryAddress,  // Send object directly
                        cartList: state.cartList,  // Send array directly
                        userData: state.userData,
                    }),
                });
    
                if (response.ok) {
                    // const data = await response.json();
                    toast.success(`Order placed successfully!`, {
                        position: 'top-right',
                        autoClose: 5000,
                        // ... other toast options
                    });
    
                    dispatch({
                        type: 'UPDATE_LOADER',
                        payload: true,
                    });
    
                    setTimeout(() => {
                        navigate('/');
                    }, 3000);
    
                    emptyCart(state.cartList);
    
                    dispatch({
                        type: 'UPDATE_COUPON_DATA',
                        payload: {
                            showCoupon: false,
                            couponDiscountName: '',
                            showCouponDetails: false,
                            couponDiscount: 0,
                        },
                    });
                } else {
                    // Handle error response
                    const errorData = await response.json();
                    console.error('Failed to place the order. Error:', errorData);
                    toast.error(`Failed to place the order. Error: ${errorData.message || 'Unknown error'}`);
                }
            } catch (error) {
                console.error('Error placing order:', error);
                toast.error('An error occurred. Please try again later.');
            }
        }
    };
    

return (
    <div className="checkout-page">
        <div className="checkout-sub-page">
            <h1 className="checkout-page-heading">Checkout</h1>
            {/* <section>
                       
                    </section> */}
            <div className="checkout-container">
                <section className="address-list-checkout">
                    <div>
                        <button onClick={() => setShowAddressAdditionPage(true)} className='newadd' > + Add New Address</button>

                        {showAddressAdditionPage ? (
                            <AddressProfile
                                showAddressAdditionPage={showAddressAdditionPage}
                                state={state}
                                dispatch={dispatch}
                                setShowAddressAdditionPage={setShowAddressAdditionPage}
                                toast={toast}
                            />
                        ) : (" ")}
                    </div>
                    {state.userAddressData.map((address) => {
                        const {
                            name,
                            colony,
                            city,
                            state,
                            country,
                            postalCode,
                            mobileNumber,
                        } = address

                        return (
                            <li>
                                <label htmlFor="address">
                                    <input
                                        type="radio"
                                        name="address"
                                        onClick={() =>
                                            dispatch({
                                                type: 'UPDATE_DELIVERY_ADDRESS',
                                                payload: address,
                                            })
                                        }
                                        className="address-input"
                                    />
                                    <div className="user-details">
                                        <h3>{name}</h3>
                                        <p>{`${colony} , ${city} ,${state} ,${country} - ${postalCode}`}</p>
                                        <p>Mobile No.: {mobileNumber}</p>
                                    </div>
                                </label>
                            </li>
                        )
                    })}
                </section>

                {showAddressAdditionPage ? '' : <section className="section-pricing-checkout">

                    <section className="checkout-pricing">

                        <div className="price-details-data">
                            <div className="price-details-keys">
                                <h3>Item</h3>
                                <ul>
                                    {state.cartList.map(
                                        ({ book: { name } }) => {
                                            return (
                                                <li className="book-data-checkout">
                                                    {name}
                                                </li>
                                            )
                                        }
                                    )}
                                </ul>
                            </div>
                            <div className="price-details-values">
                                <h3>quantity</h3>
                                <ul>
                                    {state.cartList.map(({ quantity }) => {
                                        return (
                                            <li className="book-data-checkout">
                                                {quantity}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        <section className="price-details-section">
                            <h2 className="price-details-heading">
                                PRICE DETAILS
                            </h2>
                            <div className="price-details-data">
                                <div className="price-details-keys">
                                    <p>
                                        Price ({state.cartList.length}{' '}
                                        items)
                                    </p>
                                    <p>Discount</p>
                                    <p>Delivery Charges *</p>
                                    <p>Coupon Discount</p>
                                    {state.couponData.couponDiscountName
                                        .length > 1 &&
                                        state.couponData.showCouponDetails ===
                                        true ? (
                                        <p>
                                            {' '}
                                            <i className="fa-duotone fa-badge-check"></i>{' '}
                                            {
                                                state.couponData
                                                    .couponDiscountName
                                            }
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="price-details-values">
                                    <p>
                                        <i className="fa-solid fa-indian-rupee-sign"></i>{' '}
                                        {calculatePrice(state.cartList)}
                                    </p>
                                    <p>
                                        <i className="fa-solid fa-indian-rupee-sign"></i>{' '}
                                        {calculateDiscount(state.cartList)}
                                    </p>
                                    <p>FREE</p>
                                    <p>
                                        <i className="fa-solid fa-indian-rupee-sign"></i>{' '}
                                        {calculateCouponDiscount(
                                            state.cartList
                                        )}
                                    </p>
                                    {calculateCouponDiscount(
                                        state.cartList
                                    ) > 0 &&
                                        state.couponData.showCouponDetails ===
                                        true ? (
                                        <p>
                                            {' '}
                                            <i
                                                className="fa-solid fa-xmark discard-coupon"
                                                onClick={() => {
                                                    dispatch({
                                                        type: 'UPDATE_COUPON_DISCOUNT',
                                                        payload: 0,
                                                    })
                                                    dispatch({
                                                        type: 'UPDATE_COUPON_DISCOUNT_NAME',
                                                        payload: '',
                                                    })
                                                }}
                                            ></i>
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </section>
                        <section className="total-amount">
                            <p>Total Amount</p>
                            <p>
                                <i className="fa-solid fa-indian-rupee-sign"></i>{' '}
                                {totalAmount(state.cartList)}
                            </p>
                        </section>
                        <section className="delivery-address">
                            <h2 className="price-details-heading">
                                DELIVER TO
                            </h2>
                            <h3 className="user-name-checkout">
                                {state.deliveryAddress?.name}
                            </h3>
                            {state.deliveryAddress === null ? (
                                ''
                            ) : (
                                <p className="user-address-checkout">{`${state.deliveryAddress?.colony} , ${state.deliveryAddress?.city} ,${state.deliveryAddress?.state} ,${state.deliveryAddress?.country} - ${state.deliveryAddress?.postalCode}`}</p>
                            )}
                            {state.deliveryAddress === null ? (
                                ''
                            ) : (
                                <p className="user-address-checkout">
                                    Mobile No.:{' '}
                                    {state.deliveryAddress?.mobileNumber}
                                </p>
                            )}
                        </section>
                        <button
                            className="btn-place-order"
                            onClick={placeOrderBtnHandler}
                        >
                            Place Order
                        </button>
                    </section>
                </section>}




            </div>
        </div>
        <ToastContainer />
    </div>
)
}

export default Checkout
