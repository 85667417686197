import { useContext } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { AppContext } from '../..'
import './Navbar.css'

export const Navbar = () => {
    const location = useLocation()

    const { state, dispatch } = useContext(AppContext)

    return (
        <nav>
            <div className="nav-sub-sections">
                {location?.pathname === '/shop' ? (
                    <div
                        className="mobile-nav"
                        onClick={() =>
                            dispatch({ type: 'UPDATE_MOBILE_FILTER' })
                        }
                    >
                        <i
                            id="bar"
                            className={
                                state.isFiltersOpen
                                    ? 'fa-solid fa-xmark'
                                    : 'fa-solid fa-bars'
                            }
                        ></i>
                    </div>
                ) : (
                    ''
                )}

                <Link to="/" className="main-heading">
                    <h1 className='logo'> 
                        <div className="kite-emoji">🪁</div> Rozgaar Center
                    </h1>
                </Link>

                <div className='mid'>
                    <ul>
                        {/* <li>Home</li> */}
                        <li> <Link to='/admitCard'>Admit Card</Link> </li>
                        <li> <Link to='/latestJob'>Latest Jobs</Link> </li>
                        <li>  <Link to='/results'>Results</Link> </li>
                        <li>  <Link to='/syllabus'>Syllabus</Link> </li>
                        <li>  <Link to='/answerKey'>Answer Keys</Link> </li>
                        <li>  <Link to='/others'>Others</Link> </li>
                        {/* <li>Contact Us</li>     */}
                    </ul>
                </div>
                <div className="link-items">
                    <NavLink className="nav-items" alt="wishlist" to="/wishlist">
                        <i className="fa-solid fa-heart" alt="wishlist"></i>
                        {state.wishList?.length > 0 && state.userLoggedIn ? (
                            <p className="list-count-nav">
                                {state.wishList?.length}
                            </p>
                        ) : (
                            ''
                        )}
                    </NavLink>
                    <NavLink className="nav-items" alt="cart" to="/cart">
                        <i className="fa-solid fa-cart-shopping"  alt="cart"></i>
                        {state.cartList?.length > 0 && state.userLoggedIn ? (
                            <p className="list-count-nav">
                                {state.cartList?.length}
                            </p>
                        ) : (
                            ''
                        )}
                    </NavLink>
                    <NavLink className="nav-items" alt="user" to="/login">
                        <i className="fa-solid fa-user"  alt="user"></i>
                    </NavLink>
                </div>
            </div>
        </nav>
    )
}
