import React from 'react'
import { Helmet } from "react-helmet"

const Layout = ({title, description, keywords, author, children}) => {
    return (
        <>
            <Helmet>
                <meta name="google-adsense-account" content="ca-pub-4960929194908788" />
                <meta charSet="utf-8" />
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta name="author" content={author} />
                <title>{title}</title>

                {/* Google Analytics Script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-0WZ5T7RQFN"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-0WZ5T7RQFN');
          `}
        </script>
        {/* End of Google Analytics Script */}
            </Helmet>
            {children}
        </>
    )
}

Layout.defaultProps = {
    title: 'RozgaarCenter.com : Latest Online Form | Admit Card | Results 2024',
    description: 'Rozgaar Center : RozgaarCenter.com provides latest Sarkari Result Jobs, Online Form, Sarkari Naukri Result in Rozgaar Center 2024 various sectors such as Railway, Bank, SSC, Navy, Police, UPPSC, UPSSSC, UPTET, UP Scholarship, Job Vacancy and other Rozgaar Center alerts at one place',
    keywords: 'Rozgaar Center, Rojgar, Rojgar Center, Sarkari Results, sarkari result 2024,  latest sarkari result, Rozgaar, Sarkari, Job Vacancy, 2024 Admit Card, results 2024, New Vacancy 2024, Sarkari Exam, RozgaarCenter.com',
    author: 'rozgaarcenter'
}

export default Layout