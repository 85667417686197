import React, { useState, useEffect } from 'react'; 
import axios from 'axios';
import './jobList.css'
import { Link } from "react-router-dom";


const JobList = () => {
  const [jobData, setJobData] = useState({
    results: [],
    admitCards: [],
    syllabus: [],
    latestJobs: [],
    answerKeys: [],
    others: [],
  });

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URI}/job/jobResult`)
    .then(res => {
      setJobData(prevState => ({ ...prevState, results: res.data}));
    })
    .catch(error => console.error('Error fetching result jobs:', error));
      

    axios.get(`${process.env.REACT_APP_URI}/job/jobAdmitCard`)
      .then(res => {
        setJobData(prevState => ({ ...prevState, admitCards: res.data}));
      })
      .catch(error => console.error('Error fetching job names:', error));


      axios.get(`${process.env.REACT_APP_URI}/job/jobLatestJob`)
      .then(res => {
        setJobData(prevState => ({ ...prevState, latestJobs: res.data }));
      })
      .catch(error => console.error('Error fetching job names:', error));



      /* */


      axios.get(`${process.env.REACT_APP_URI}/job/jobSyllabus`)
      .then(res => {
        setJobData(prevState => ({ ...prevState, syllabus: res.data }));
      })
      .catch(error => console.error('Error fetching job names:', error));


    axios.get(`${process.env.REACT_APP_URI}/job/jobAnswerKey`)
      .then(res => {
        setJobData(prevState => ({ ...prevState, answerKeys: res.data}));
      })
      .catch(error => console.error('Error fetching job names:', error));

    axios.get(`${process.env.REACT_APP_URI}/job/jobOthers`)
      .then(res => {
        setJobData(prevState => ({ ...prevState, others: res.data}));
      })
      .catch(error => console.error('Error fetching job names:', error));
  }, []);

  return (
    <>
    
    <div className="job-list-container">
    <div className="job-section">
        <h2>Results</h2>
        <ul>
          {jobData.results.map(job => (
            <li key={job.slug}>
              <a href={`/results/${job.slug}`}>
                {job.jobName}
              </a>
            </li>
          ))}
          <div className='view-more-link'>
          <Link to='/results'>View More</Link>
          </div>
        </ul>
      </div>

      <div className="job-section hide">
        <h2>Latest Jobs</h2>
        <ul>
          {jobData.latestJobs.map(latestJob => (
            <li key={latestJob.slug}>
              <a href={`/latestJob/${latestJob.slug}`}>
                {latestJob.jobName}
              </a>
            </li>
          ))}
          <div className='view-more-link'>
          <Link to='/latestJob'>View More</Link>
          </div>
        </ul>
      </div>

            
      <div className="job-section">
        <h2>Admit Cards</h2>
        <ul>

        {jobData.admitCards.map(admitCard => (
            <li key={admitCard.slug}>
              <a href={`/admitCard/${admitCard.slug}`}>
                {admitCard.jobName}
              </a>
            </li>
          ))}
          <div className='view-more-link'>
          <Link to='/admitCard'>View More</Link>
          </div>
        </ul>
      </div>
      
  </div>

  <div className="job-list-container yes-hide">

  <div className="job-section">
        <h2>Latest Jobs</h2>
        <ul>
          {jobData.latestJobs.map(latestJob => (
            <li key={latestJob.slug}>
              <a href={`/latestJob/${latestJob.slug}`}>
                {latestJob.jobName}
              </a>
            </li>
          ))}
          <div className='view-more-link'>
          <Link to='/latestJob'>View More</Link>
          </div>
        </ul>
      </div>

  <div className="job-section">
        <h2>Others</h2>
        <ul>
          {jobData.others.map(other => (
            <li key={other.slug}>
              <a href={`/others/${other.slug}`}>
                {other.jobName}
              </a>
            </li>
          ))}
          <div className='view-more-link'>
          <Link to='/others'>View More</Link>
          </div>
        </ul>
      </div>

  </div>
  
  <div className="job-list-container">
  
  <div className="job-section">
        <h2>Syllabus</h2>
        <ul>
          {jobData.syllabus.map(syllabu => (
            <li key={syllabu.slug}>
              <a href={`/syllabus/${syllabu.slug}`}>
                {syllabu.jobName}
              </a>
            </li>
          ))}
          <div className='view-more-link'>
          <Link to='/syllabus'>View More</Link>
          </div>
        </ul>
      </div>

      <div className="job-section">
        <h2>Answer Keys</h2>
        <ul>
          {jobData.answerKeys.map(answerKey => (
            <li key={answerKey.slug}>
              <a href={`/answerKey/${answerKey.slug}`}>
                {answerKey.jobName}
              </a>
            </li>
          ))}
          <div className='view-more-link'>
          <Link to='/answerKey'>View More</Link>
          </div>
        </ul>
      </div>

      <div className="job-section hide">
        <h2>Others</h2>
        <ul>
          {jobData.others.map(other => (
            <li key={other.slug}>
              <a href={`/others/${other.slug}`}>
                {other.jobName}
              </a>
            </li>
          ))}
          <div className='view-more-link'>
          <Link to='/others'>View More</Link>
          </div>
        </ul>
      </div>
    </div>
  
  </>

  
  );
};

export default JobList;