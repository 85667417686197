// AboutUs.jsx

import React, {useEffect, useContext} from 'react';
import { AppContext } from '../..'
import './AboutUs.css'; // Import your CSS file for styling
import Footer from '../Footer/Footer';

const AboutUs = () => {

    const { dispatch } = useContext(AppContext)

    useEffect(() => {
        dispatch({ type: 'UPDATE_LOADER', payload: false });
    }, [dispatch]);

  return (
    <>
    
    <div className="about-us-container">
      <h1 className="about-us-title">About Us</h1>
      <p className="about-us-content">
        Welcome to <a href="https://www.Rozgaarcenter.com">Rozgaarcenter.com</a>! We are passionate about connecting individuals with employment opportunities and providing a seamless experience for job seekers and employers alike.
        <br /><br />
        <strong>Our Mission:</strong><br />
        At <a href="https://www.Rozgaarcenter.com">Rozgaarcenter.com</a>, our mission is to simplify the job search process by offering a platform that caters to the diverse needs of our users. We strive to empower individuals to find the right job opportunities and assist employers in finding qualified candidates.
        <br /><br />
        <strong>What Sets Us Apart:</strong><br />
        - Comprehensive Job Listings: Explore a wide range of job listings from various industries.
        <br />
        - User-Friendly Platform: Our website is designed to be intuitive and easy to navigate, ensuring a positive user experience.
        <br />
        - Dedicated Support: Our team is committed to providing excellent customer support to address any queries or concerns.
        <br /><br />
        <strong>Contact Us:</strong><br />
        Have questions or feedback? We'd love to hear from you! Reach out to us at <a href="mailto:rozgaarcenter2024@gmail.com">rozgaarcenter2024@gmail.com</a>.
      </p>
    </div>
    
    <Footer/>
    
    </>
  );
};

export default AboutUs;
